.react-confirm-alert-overlay{
    background: rgba(0,0,0,0.5) !important;
}

.react-confirm-alert-body{
    background: #14161b !important;
    color: #fff !important;
}

.react-confirm-alert-body > h1 {
    color: #fff !important;
}
.react-confirm-alert-button-group > button {
    background:  #1da750 !important;
}